var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instore-detail" },
    [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c(
            "topOperatingButton",
            {
              attrs: {
                disabled: _vm.disabled,
                showAudit: _vm.showAudit,
                isAddBtn: _vm.showAdd,
                id: "topOperatingButton",
              },
              on: {
                submitForm: function ($event) {
                  return _vm.handleSubmit(false)
                },
                addBill: function ($event) {
                  return _vm.handleSubmit(true)
                },
                auditBill: _vm.changeBillStatus,
                handleAdd: _vm.handleAdd,
                getQuit: _vm.getQuit,
              },
            },
            [
              _c(
                "template",
                { slot: "specialDiyBtn" },
                [
                  _vm.billType !== "110101" &&
                  _vm.billType !== "120101" &&
                  _vm.billType !== "140313"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", disabled: _vm.disabled },
                          on: { click: _vm.selectBills },
                        },
                        [_vm._v("引入源单 ")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-remove-outline",
                        size: "mini",
                        disabled: _vm.single,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeBillStatus("关闭")
                        },
                      },
                    },
                    [_vm._v("终止单据 ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "ken-detail-form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                "label-position": "right",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "cardTitleCom",
                {
                  attrs: {
                    cardTitle: "基本信息",
                    billStatus: _vm.form.billStatus,
                    id: "basicInformation",
                  },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "x-f marT10" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "单据编号", prop: "billNo" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "单据编号",
                                disabled: "",
                                size: "mini",
                              },
                              model: {
                                value: _vm.form.billNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "billNo", $$v)
                                },
                                expression: "form.billNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "单据日期", prop: "billDate" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                disabled: _vm.disabled,
                                "value-format": "yyyy-MM-dd",
                                size: "mini",
                              },
                              model: {
                                value: _vm.form.billDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "billDate", $$v)
                                },
                                expression: "form.billDate",
                              },
                            }),
                          ],
                          1
                        ),
                        ["110101", "120101", "140313"].includes(_vm.billType)
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "到货日期",
                                  prop: "arrivalDate",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                    disabled: _vm.disabled,
                                    "value-format": "yyyy-MM-dd",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.arrivalDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "arrivalDate", $$v)
                                    },
                                    expression: "form.arrivalDate",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.billType != "140313" && _vm.BillSaleType
                          ? _c(
                              "el-form-item",
                              {
                                attrs: { label: " 供应商 ", prop: "partnerId" },
                              },
                              [
                                _c("SelectFrame", {
                                  ref: "selectFrame",
                                  attrs: {
                                    tableData: _vm.partnerData,
                                    disabled: _vm.disabled || _vm.sourceStatus,
                                    router: "/partner/supplier/newSupplier",
                                    normalizer: _vm.normalizerSupp,
                                    size: "mini",
                                  },
                                  on: {
                                    getPitchId: function ($event) {
                                      return _vm.getDiyselectId(
                                        $event,
                                        "partnerId",
                                        "form",
                                        "供应商"
                                      )
                                    },
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        path: "/goods/partner/supplier",
                                      })
                                    },
                                  },
                                  model: {
                                    value: _vm.form.partnerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "partnerName", $$v)
                                    },
                                    expression: "form.partnerName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.BillSaleType
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "客户", prop: "partnerId" } },
                              [
                                _c("SelectFrame", {
                                  ref: "selectFrame",
                                  attrs: {
                                    tableData: _vm.customerData,
                                    disabled: _vm.disabled || _vm.sourceStatus,
                                    router: "/partner/customer/newCustomers",
                                    normalizer: _vm.normalizerCus,
                                    size: "mini",
                                  },
                                  on: {
                                    getPitchId: function ($event) {
                                      return _vm.getDiyselectId(
                                        $event,
                                        "partnerId",
                                        "form",
                                        "客户"
                                      )
                                    },
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        path: "/goods/partner/customer",
                                      })
                                    },
                                  },
                                  model: {
                                    value: _vm.form.partnerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "partnerName", $$v)
                                    },
                                    expression: "form.partnerName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        ["140303"].includes(_vm.billType)
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "仓库", prop: "inStoreId" } },
                              [
                                _c("SelectFrame", {
                                  ref: "selectStore",
                                  attrs: {
                                    tableData: _vm.storeDataObj,
                                    disabled: _vm.disabled,
                                    normalizer: _vm.normalizerStore,
                                    size: "mini",
                                  },
                                  on: {
                                    getPitchId: function ($event) {
                                      return _vm.getDiyselectId(
                                        $event,
                                        "inStoreId",
                                        "form"
                                      )
                                    },
                                    clickAdd: function ($event) {
                                      _vm.OpenStoreAdd = true
                                    },
                                    click: function ($event) {
                                      _vm.OpenStore = true
                                    },
                                  },
                                  model: {
                                    value: _vm.form.inStoreName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "inStoreName", $$v)
                                    },
                                    expression: "form.inStoreName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        ["140313"].includes(_vm.billType)
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "仓库", prop: "storeId" } },
                              [
                                _c("SelectFrame", {
                                  ref: "selectStore",
                                  attrs: {
                                    tableData: _vm.storeDataObj,
                                    disabled: _vm.disabled,
                                    normalizer: _vm.normalizerStore,
                                    size: "mini",
                                  },
                                  on: {
                                    getPitchId: function ($event) {
                                      return _vm.getDiyselectId(
                                        $event,
                                        "storeId",
                                        "form"
                                      )
                                    },
                                    clickAdd: function ($event) {
                                      _vm.OpenStoreAdd = true
                                    },
                                    click: function ($event) {
                                      _vm.OpenStore = true
                                    },
                                  },
                                  model: {
                                    value: _vm.form.storeName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "storeName", $$v)
                                    },
                                    expression: "form.storeName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        ["140307", "140312"].includes(_vm.billType)
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "仓库", prop: "outStoreId" } },
                              [
                                _c("SelectFrame", {
                                  ref: "selectStore",
                                  attrs: {
                                    tableData: _vm.storeDataObj,
                                    disabled: _vm.disabled,
                                    normalizer: _vm.normalizerStore,
                                    size: "mini",
                                  },
                                  on: {
                                    getPitchId: function ($event) {
                                      return _vm.getDiyselectId(
                                        $event,
                                        "outStoreId",
                                        "form"
                                      )
                                    },
                                    clickAdd: function ($event) {
                                      _vm.OpenStoreAdd = true
                                    },
                                    click: function ($event) {
                                      _vm.OpenStore = true
                                    },
                                  },
                                  model: {
                                    value: _vm.form.outStoreName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "outStoreName", $$v)
                                    },
                                    expression: "form.outStoreName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "手工单据号", prop: "handBillNo" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: _vm.disabled,
                                placeholder: "手工单据号",
                                size: "mini",
                                oninput:
                                  "value=value.replace(/[^0-9a-zA-Z]/g,'')",
                              },
                              model: {
                                value: _vm.form.handBillNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "handBillNo", $$v)
                                },
                                expression: "form.handBillNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        ["120101", "140313"].includes(_vm.billType)
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "生产工厂",
                                  prop: "produceDeptId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "billType marR5",
                                    attrs: {
                                      placeholder: "请选择",
                                      filterable: "",
                                      clearable: "",
                                      disabled: _vm.disabled,
                                      size: "mini",
                                    },
                                    model: {
                                      value: _vm.form.produceDeptId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "produceDeptId", $$v)
                                      },
                                      expression: "form.produceDeptId",
                                    },
                                  },
                                  _vm._l(_vm.produceLists, function (item) {
                                    return _c("el-option", {
                                      key: item.produceDeptNo,
                                      attrs: {
                                        label: item.produceDeptName,
                                        value: item.produceDeptId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { label: "部门", prop: "deptId" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "billType marR5",
                                attrs: {
                                  placeholder: "请选择",
                                  filterable: "",
                                  clearable: "",
                                  disabled: _vm.disabled,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.form.deptId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "deptId", $$v)
                                  },
                                  expression: "form.deptId",
                                },
                              },
                              _vm._l(_vm.deptData, function (item) {
                                return _c("el-option", {
                                  key: item.deptId,
                                  attrs: {
                                    label: item.deptName,
                                    value: item.deptId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.BillSaleType
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "采购员",
                                  prop: "purEmployeeId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      disabled: _vm.disabled,
                                      filterable: "",
                                      clearable: "",
                                      size: "mini",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$refs.form.validateField(
                                          "purEmployeeId"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.form.purEmployeeId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "purEmployeeId", $$v)
                                      },
                                      expression: "form.purEmployeeId",
                                    },
                                  },
                                  _vm._l(_vm.employeeData, function (item) {
                                    return _c("el-option", {
                                      key: item.employeeId,
                                      attrs: {
                                        label: item.employeeName,
                                        value: item.employeeId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.BillSaleType
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "业务员",
                                  prop: "saleEmployeeId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      disabled: _vm.disabled,
                                      filterable: "",
                                      size: "mini",
                                      remote: "",
                                      "reserve-keyword": "",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$refs.form.validateField(
                                          "saleEmployeeId"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.form.saleEmployeeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "saleEmployeeId",
                                          $$v
                                        )
                                      },
                                      expression: "form.saleEmployeeId",
                                    },
                                  },
                                  _vm._l(_vm.employeeData, function (item) {
                                    return _c("el-option", {
                                      key: item.employeeId,
                                      attrs: {
                                        label: item.employeeName,
                                        value: item.employeeId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "itemAddress",
                            attrs: { label: "收货地址", prop: "storeAddress" },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "410px" },
                                attrs: {
                                  readonly: "",
                                  placeholder: "请点击右边图标可编辑收货地址",
                                  disabled: _vm.disabled,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.form.storeAddressJoin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "storeAddressJoin", $$v)
                                  },
                                  expression: "form.storeAddressJoin",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-more",
                                  attrs: { slot: "suffix" },
                                  on: { click: _vm.setAddress },
                                  slot: "suffix",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  staticClass: "headGoodsTable",
                  attrs: { cardTitle: "商品信息" },
                },
                [
                  _c("template", { slot: "rightCardTitle" }, [
                    _c(
                      "div",
                      { staticClass: "marR10" },
                      [
                        !["140303", "140307", "140312", "140313"].includes(
                          _vm.billType
                        )
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.disabled,
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getDistribution()
                                  },
                                },
                              },
                              [_vm._v("批量修改仓库")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "instore-table" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c("keyBoardTable", {
                                  key: _vm.tableKey,
                                  ref: "myTable",
                                  attrs: {
                                    isDisabled: _vm.disabled,
                                    tableHeight: "450px",
                                    isShowCheckbox: true,
                                    uuid: _vm.uuid,
                                    isCreatedInit: _vm.isCreatedInit,
                                    isTableContentDisabled:
                                      _vm.isTableContentDisabled,
                                    tableColumns: _vm.originColumns,
                                    tableColumnsCopy: _vm.originColumnsCopy,
                                  },
                                  on: {
                                    handleSelectionChange:
                                      _vm.handleSelectionChange,
                                    handleTableInput: _vm.handleTableInput,
                                    handleTableBlur: _vm.handleTableBlur,
                                    handleTableSelectChange:
                                      _vm.handleTableSelectChange,
                                    handleSyncTableData:
                                      _vm.handleSyncTableData,
                                    handleTableCallback:
                                      _vm.handleTableCallback,
                                    footerMethod: _vm.footerMethod,
                                    handleTableColumnConfig:
                                      _vm.handleTableColumnConfig,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "instore-footer" },
                [
                  _c("accessoryUpload", {
                    attrs: {
                      listType: "img",
                      disabled: _vm.disabled,
                      fileList: _vm.form.fileItems,
                    },
                    on: {
                      getFileItems: _vm.getFileItemsData,
                      delFileItems: _vm.delFileItems,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "right-remark" },
                    [
                      _c("span", [_vm._v(" 单据备注:")]),
                      _c("el-input", {
                        staticClass: "input",
                        attrs: {
                          placeholder: "备注",
                          disabled: _vm.disabled,
                          type: "textarea",
                          rows: 3,
                        },
                        model: {
                          value: _vm.form.billRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "billRemark", $$v)
                          },
                          expression: "form.billRemark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("SelectGoods", {
            attrs: {
              OpenGoods: _vm.OpenGoods,
              queryCode: _vm.queryCode,
              searchType: _vm.searchType,
              isEnablePurchase: _vm.isEnablePurchase,
              isEnableSale: _vm.isEnableSale,
              extraParams: _vm.selectGoodsExtraParams,
            },
            on: {
              "update:OpenGoods": function ($event) {
                _vm.OpenGoods = $event
              },
              "update:open-goods": function ($event) {
                _vm.OpenGoods = $event
              },
              getGoodsIds: function ($event) {
                return _vm.getGoods($event, true)
              },
            },
          }),
          _c("SelectBills", {
            attrs: {
              title: _vm.title,
              NoFields: _vm.NoFields,
              tableColumnFields: _vm.tableColumnFields,
              billList: _vm.billList,
              openDialog: _vm.openBillDia,
              loadingDia: _vm.loadingDia,
              billTotal: _vm.billTotal,
              detailList: _vm.form.details,
              allBillList: _vm.allBillList,
              billType: _vm.billType,
            },
            on: {
              "update:openDialog": function ($event) {
                _vm.openBillDia = $event
              },
              "update:open-dialog": function ($event) {
                _vm.openBillDia = $event
              },
              getSelect: _vm.selectBills,
              getAllBills: _vm.getSelectBills,
              getBillsIds: function ($event) {
                return _vm.getSelectIds($event, true)
              },
              search: function (searchObj) {
                return _vm.selectBills(searchObj)
              },
            },
          }),
          _c("selectSupplier", {
            attrs: { openSelectSupplier: _vm.openSelectSupplier },
            on: {
              "update:openSelectSupplier": function ($event) {
                _vm.openSelectSupplier = $event
              },
              "update:open-select-supplier": function ($event) {
                _vm.openSelectSupplier = $event
              },
              getSupplierId: function ($event) {
                return _vm.getSupplier($event, "form")
              },
            },
          }),
          _c("selectStore", {
            attrs: { OpenStore: _vm.OpenStore, billType: _vm.billType },
            on: {
              "update:OpenStore": function ($event) {
                _vm.OpenStore = $event
              },
              "update:open-store": function ($event) {
                _vm.OpenStore = $event
              },
              getStore: function ($event) {
                return _vm.getStore($event, "form", "", "selectStore")
              },
            },
          }),
          _c("storeAdd", {
            attrs: { OpenStoreAdd: _vm.OpenStoreAdd },
            on: {
              "update:OpenStoreAdd": function ($event) {
                _vm.OpenStoreAdd = $event
              },
              "update:open-store-add": function ($event) {
                _vm.OpenStoreAdd = $event
              },
              getStore: function ($event) {
                return _vm.getStore($event, "form")
              },
            },
          }),
          _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "dialogDrag",
                  rawName: "v-dialogDrag",
                  value: true,
                  expression: "true",
                },
              ],
              staticClass: "AddressDialog",
              attrs: {
                title: "收货地址",
                visible: _vm.openAddress,
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.openAddress = $event
                },
              },
            },
            [
              _vm.form.storeAddress
                ? _c(
                    "el-form",
                    {
                      ref: "AddressForm",
                      attrs: {
                        model: _vm.form.storeAddress,
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "联系人",
                                    prop: "contactMan",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "请输入联系人",
                                      maxlength: "30",
                                    },
                                    model: {
                                      value: _vm.form.storeAddress.contactMan,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.storeAddress,
                                          "contactMan",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.storeAddress.contactMan",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "联系电话",
                                    prop: "contactTel",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "请输入联系电话",
                                      maxlength: "30",
                                    },
                                    model: {
                                      value: _vm.form.storeAddress.contactTel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.storeAddress,
                                          "contactTel",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.storeAddress.contactTel",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "地址",
                                    prop: "storeAddress",
                                  },
                                },
                                [
                                  _c("el-cascader", {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.loading,
                                        expression: "loading",
                                      },
                                    ],
                                    ref: "cascader",
                                    staticClass: "provinceListClass",
                                    attrs: {
                                      options: _vm.provinceList,
                                      props: _vm.defaultProp,
                                      maxlength: "80",
                                      emitPath: "",
                                    },
                                    on: { change: _vm.getAddressId },
                                    model: {
                                      value: _vm.form.storeAddress.regionId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.storeAddress,
                                          "regionId",
                                          $$v
                                        )
                                      },
                                      expression: "form.storeAddress.regionId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "详细地址",
                                    prop: "addressDetail",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "详细地址",
                                      maxlength: "30",
                                    },
                                    model: {
                                      value:
                                        _vm.form.storeAddress.addressDetail,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.storeAddress,
                                          "addressDetail",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.storeAddress.addressDetail",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveAddress },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.openAddress = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      ["110101"].includes(_vm.billType)
        ? _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "left" }),
            _c("div", { staticClass: "right" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "label" }, [_vm._v("单据金额 :")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.billMoney)),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }